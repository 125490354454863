import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import public_calendars_1 from '../../../../../../../common/src/assets/image/public_calendars_1.png';
import public_calendars_2 from '../../../../../../../common/src/assets/image/public_calendars_2.png';
import public_calendars_3 from '../../../../../../../common/src/assets/image/public_calendars_3.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing holidays, sporting events, and many other public events. To
        include public events in your printable calendars, follow these steps:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar </strong>button on the{' '}
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>{' '}
          step and select the{' '}
          <strong>Online -&gt; Holidays, Sports, More</strong> option. The{' '}
          <strong>Add Calendar Source - Holidays, Sports, More</strong> window
          will appear.
          <br />
          <Image alt="" src={public_calendars_1} style={{ width: 489 }} />
        </li>
        <li>
          Calendars are categorized by type, such as Holidays, Religion,
          Science, Sports, etc. To view calendars under a certain category,
          click the expansion arrow to the left of the category name. To view
          details about a calendar, hover the mouse over the calendar's name.
          <br />
          <Image alt="" src={public_calendars_2} style={{ width: 489 }} />
        </li>
        <li>
          The list of calendar can be searched. To search, enter a word or
          phrase in the Search box shown above the calendar list.
          <br />
          <Image alt="" src={public_calendars_3} style={{ width: 596 }} />
        </li>
        <li>
          Use the checkboxes to the left of the calendar names to select the
          data sources you'd like to include.
        </li>
        <li>When finished selecting calendars, click the OK button.</li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Holidays-Sports-More"
      commentsId="commentsplus_post_139_489"
      title="Create Printable Calendars Containing Holidays, Sporting Events, and More"
      description="PrintableCal can can create printable calendars containing holidays, sporting events, and many other public events."
      keywords="print holidays, print sports, print public calendars, Excel calendar, Word calendar, import holidays, import team schedules, import calendars, printable calendar, calendar template"
      content={content}
    />
  );
};

export default Documentation;
